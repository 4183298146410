import { FC } from 'react';
import './skeleton.scss';

type Props = {
  className?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  style?: React.CSSProperties;
};

export const Skeleton: FC<Props> = ({ className, style, width, height }) => {
  return (
    <div
      className={`skeleton ${className || ''}`}
      style={{
        height: height || '100%',
        width: width || '100%',
        ...style,
      }}></div>
  );
};
