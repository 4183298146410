import { UniqueIdentifier } from '@dnd-kit/core';

export const findContainer = (
  id: UniqueIdentifier,
  items: { [key: string]: any }
) => {
  if (Object.keys(items).includes(String(id))) {
    return id;
  }

  return Object.keys(items).find((key) =>
    items[key].find((item) => item.id === id)
  );
};

// default 1 row even if no data in overContainer
const MAX_ITEMS_PER_ROW = 4;

export const getRowCount = (items: number) => {
  return Math.ceil(items / MAX_ITEMS_PER_ROW) || 1;
};
