import { memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useTranslation } from 'hooks';
import { RootState } from 'store';
import { getQuickActions } from 'api/home';
import { setQuickActions } from 'actions/home';
import { openModal } from 'actions/modal';
import { fillEmptyActions } from './helpers';
import {
  QuickActionCard,
  SmallBanner,
  WrapperBlock,
} from 'pages/home/components';
import Button from 'components/ui/button';
import './quickActions.scss';

SwiperCore.use([Navigation]);

export const QuickActions: React.FC = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const getTranslate = useTranslation();
  const { enabled, isLoaded = false } = useSelector(
    (state: RootState) => state.home.quickActions
  );

  useEffect(() => {
    if (!isLoaded) {
      fetchQuickActions();
    }
  }, [isLoaded]);

  const fetchQuickActions = async () => {
    try {
      const { quickActions } = await getQuickActions();
      dispatch(setQuickActions({ quickActions }));
    } catch (err) {
      console.error(err);
    }
  };

  const enabledActions = useMemo(() => {
    const actions = fillEmptyActions(enabled).map((item) => {
      if (!item) {
        return {
          id: '',
          icon: 'im-Plus',
          title: 'getStarted.quickActions.add.label',
        };
      }

      return item;
    });

    if (window.innerWidth < 768) {
      return actions.filter(({ id }) => id);
    }

    return actions;
  }, [enabled]);

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalId: 'QuickActionsSetting',
        callback: (result) => {
          if (result) {
            dispatch(setQuickActions({ quickActions: result }));
          }
        },
      })
    );
  };

  return (
    <WrapperBlock
      title={getTranslate('getStarted.quickActions.header')}
      actions={
        <Button
          status='light'
          text={getTranslate('common.edit.button')}
          icon='im-Configurator'
          iconPosition='left'
          iconSize={14}
          customClass='quick-actions-edit'
          onClick={handleOpenModal}
        />
      }>
      <div className='quick-actions-row-with-banner'>
        <div className='quick-actions'>
          <Swiper
            enabled={window.innerWidth <= 768}
            breakpoints={{
              320: {
                slidesPerView: 2.1,
                spaceBetween: 8,
              },
              560: {
                slidesPerView: 3.5,
                spaceBetween: 8,
              },
              769: {
                slidesPerView: 4,
                spaceBetween: 16,
              },
            }}>
            {isLoaded
              ? enabledActions.map(({ id, icon, title }, idx) => (
                  <SwiperSlide key={idx}>
                    <QuickActionCard
                      isNew={!id}
                      iconName={icon}
                      title={getTranslate(title)}
                      onClick={!id ? handleOpenModal : () => history.push(id)}
                    />
                  </SwiperSlide>
                ))
              : [1, 2, 3, 4].map((item) => (
                  <SwiperSlide key={item}>
                    <QuickActionCard isLoading />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>

        <SmallBanner />
      </div>
    </WrapperBlock>
  );
});
