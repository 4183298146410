import RpcTypes from 'constants/rpcTypes';
import RpcService from 'helpers/RpcService';
import { TodayBlock, QuickAction } from 'reducers/home';

export const getAnalysisDataByCodeName = (payload: {
  blockCode: string;
  currency: string;
  periodType?: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.GetStarted_GetInfoBlock,
        payload,
      },
    ],
  });
};

export const saveQuickActions = (quickActions: QuickAction[]) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Set,
        payload: { param: 'quickActions', value: quickActions },
      },
    ],
  });
};

export const getQuickActions = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Get,
        payload: { param: 'quickActions' },
      },
    ],
  });
};

export const getTodayBlocks = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Get,
        payload: { param: 'getStartedInfoBlocks' },
      },
    ],
  });
};

export const saveTodayBlocks = (todayBlocks: TodayBlock[]) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Set,
        payload: { param: 'getStartedInfoBlocks', value: todayBlocks },
      },
    ],
  });
};
