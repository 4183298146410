import { QuickAction } from 'reducers/home';

export function fillEmptyActions(actions: QuickAction[]) {
  const emptyActions: (QuickAction | null)[] = Array(4).fill(null);

  if (actions) {
    actions.forEach((item) => {
      emptyActions[item.index!] = item;
    });
  }

  return emptyActions;
}
