import { RootState } from 'store';
import { MainBanner } from './MainBanner';
import { useSelector } from 'react-redux';
import { BoardingStage } from 'types/BoardingStage';
import { usePermission } from 'hooks';
import RpcTypes from 'constants/rpcTypes';

const statusesToDisplayBanner: BoardingStage[] = ['onboarding_fill'];

export const MainBannerContainer: React.FC = () => {
  const { isEnabled } = usePermission();
  const { activeLegalEntities } = useSelector((state: RootState) => state.user);
  const boardingStatus: BoardingStage =
    activeLegalEntities?.[0]?.boarding_status;

  if (!isEnabled(RpcTypes.Onboarding_Business_Docs)) {
    return null;
  }

  if (!statusesToDisplayBanner.includes(boardingStatus)) {
    return null;
  }

  return <MainBanner />;
};
