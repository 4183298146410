import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'hooks';
import { RootState } from 'store';
import { getAnalysisDataByCodeName } from 'api/home';
import { currencyCodeToSymbol } from 'pages/home/helpers/mapper';
import { setTodayBlockData } from 'actions/home';
import Icon from 'components/ui/icon';
import { Skeleton } from 'components/ui/skeleton';
import Button from 'components/ui/button';
import { OverallChip } from '../OverallChip/OverallChip';
import Utils from 'helpers/Utils';
import './summaryCard.scss';

interface Props {
  name: string;
  isDraggable?: boolean;
  isPreLoading?: boolean;
  isOverlay?: boolean;
  isDragDisabled?: boolean;
  isRedirectDisabled?: boolean;
}

export const SummaryCard: React.FC<Props> = ({
  name,
  isDraggable = false,
  isPreLoading = false,
  isOverlay = false,
  isDragDisabled = false,
  isRedirectDisabled = false,
}) => {
  const getTranslate = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isFirstRender = useRef(true);
  const [isLoading, setLoading] = useState(isPreLoading);
  const [isError, setError] = useState(false);
  const { state, currentCurrency, refreshId } = useSelector(
    (state: RootState) => ({
      state: state.home.todayBlockData[name],
      currentCurrency: state.home.currentCurrency,
      refreshId: state.home.refreshId,
    })
  );
  const { data, isLoaded = false } = state || {};
  const {
    title = '',
    totalValue = '',
    text = '',
    percentageChange = '',
    tooltip = '',
    redirectPath = '',
  } = data || {};

  useEffect(() => {
    if (!isLoaded) {
      fetchData();
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchData();
    } else {
      isFirstRender.current = false;
    }
  }, [refreshId, currentCurrency]);

  const fetchData = async () => {
    if (isPreLoading) {
      return;
    }

    setLoading(true);
    setError(false);

    try {
      const { title, subTitle, value, increase, tooltip, redirectPath } =
        await getAnalysisDataByCodeName({
          blockCode: name,
          currency: currentCurrency,
        });

      dispatch(
        setTodayBlockData({
          name: name,
          data: {
            title,
            totalValue: value,
            text: subTitle,
            percentageChange: increase,
            tooltip,
            redirectPath,
          },
        })
      );
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading || isError) {
    return (
      <div className='card summary-card'>
        <div className='summary-card__header'>
          <Skeleton height={14} width='100%' style={{ maxWidth: 100 }} />
          {isError ? (
            <Button
              id='refresh'
              status='light'
              icon='im-Refill'
              iconSize={14}
              iconPosition='left'
              text='Refresh'
              customClass='summary-card__refresh'
              onClick={fetchData}
            />
          ) : null}
        </div>
        <div>
          <div className='summary-card__total-wrapper'>
            <Skeleton height={27} width={56} />
            <Skeleton height={27} width={32} />
          </div>
          <Skeleton height={17} width='100%' style={{ maxWidth: 117 }} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames('card summary-card', {
        'summary-card_draggable': isDraggable && !isDragDisabled,
        'summary-card_drag-disabled': isDragDisabled,
        'summary-card_overlay': isOverlay,
      })}
      onClick={() => {
        if (!isRedirectDisabled) {
          history.push(redirectPath);
        }
      }}>
      <div className='summary-card__title-wrapper'>
        <h5 className='summary-card__title'>{getTranslate(title)}</h5>
        {!isDraggable && tooltip ? (
          <Icon
            tooltip={getTranslate(tooltip)}
            size={16}
            name='im-Info'
            className='summary-card__tooltip'
          />
        ) : null}
      </div>
      <div>
        <div className='summary-card__total-wrapper'>
          <h3 className='summary-card__total'>
            {name !== 'purchases_total' &&
              currencyCodeToSymbol[currentCurrency]}{' '}
            {Utils.getNumberWithSuffix(totalValue)}
          </h3>

          {percentageChange ? <OverallChip value={percentageChange} /> : null}

          {isDraggable ? (
            <Icon
              name='draggable'
              size={16}
              className='summary-card__draggable'
            />
          ) : null}
        </div>
        <p className='summary-card__text'>{text}</p>
      </div>
    </div>
  );
};
