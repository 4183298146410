import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui/icon';
import UnreadCount from 'components/ui/unreadCount';
import './helpdeskToggle.scss';

interface Props {
  onToggle: () => void;
  user: any;
  isOpened: boolean;
}

class HelpDeskToggle extends React.PureComponent<Props> {
  render() {
    const { user, isOpened } = this.props;
    const { tips } = user;
    const unreadMessages = Number(user.supportMessages?.unreadCount) || 0;
    const unreadTip =
      tips && tips.currentTip
        ? Number(tips.list?.[tips.currentTip] === false)
        : 0;

    return (
      <div
        onClick={this.props.onToggle}
        className={classNames('helpdesk-toggle', {
          'helpdesk-toggle_opened': isOpened,
        })}>
        <Icon size={17} className='helpdesk-toggle__icon' name='im-Helpdesk' />

        <UnreadCount
          count={unreadMessages + unreadTip}
          className='helpdesk-toggle__unread'
        />
      </div>
    );
  }
}

export default HelpDeskToggle;
