import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { VerticalBarChartData } from 'reducers/home';
import { OverallChip } from 'pages/home/components/OverallChip/OverallChip';
import { useTranslation } from 'hooks';
import './verticalBarChart.scss';

interface Props {
  data: VerticalBarChartData;
}

export const VerticalBarChartView: React.FC<Props> = ({ data }) => {
  const getTranslate = useTranslation();
  const [lineWidths, setLineWidth] = useState<string[]>([]);
  const { chartDate } = data;

  useEffect(() => {
    const values = chartDate.map(({ value }) => value);

    if (values.length > 0) {
      const maxValue = Math.max(...values);
      const widths = values.map(
        (value) => `${Number((value / maxValue) * 100).toFixed(2)}%`
      );

      // delay to trigger CSS transition
      const timeout = setTimeout(() => {
        setLineWidth(widths);
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [chartDate]);

  if (!chartDate.length) {
    return (
      <div className='vertical-bar-chart__no-data'>
        <h4 className='vertical-bar-chart__no-data-title'>
          {getTranslate('common.noData')}
        </h4>
        <p className='vertical-bar-chart__no-data-text'>
          {getTranslate('getStarted.graph.noData.text')}
        </p>
      </div>
    );
  }

  return (
    <div className='vertical-bar-chart'>
      {chartDate.map(({ country_code, country_name, increase, value }, idx) => (
        <div key={country_code} className='vertical-bar-chart__item'>
          <div className='vertical-bar-chart__country-wrapper'>
            <ReactCountryFlag
              countryCode={country_code}
              alt={`${country_name} flag`}
              svg
              style={{
                width: 20,
                height: 20,
                borderRadius: '100%',
                objectFit: 'cover',
              }}
            />
            <span className='vertical-bar-chart__country'>{country_name}</span>
          </div>
          <span className='vertical-bar-chart__value'>{value}</span>
          {increase ? <OverallChip value={increase} /> : null}
          <div
            className='vertical-bar-chart__line'
            style={{
              width: lineWidths[idx],
            }}></div>
        </div>
      ))}
    </div>
  );
};
