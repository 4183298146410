import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { AnyObject } from 'types/Common';
import { DictionaryCustomSelect } from 'types/FilterValue';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import { steps, fieldsConfig } from './config';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import Button from 'components/ui/button';
import { TabsContainer as Tabs, Tab } from 'components/ui/tabs';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import Icon from 'components/ui/icon';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Link from 'components/ui/link';

interface Props extends IntlProps, WithPermissions {
  fields: any;
  id?: string;
  dictionaries: {
    projectId: DictionaryCustomSelect;
    transferCurrency: DictionaryCustomSelect;
    companyLegalCountry: DictionaryCustomSelect;
    beneficiaryBankCountry: DictionaryCustomSelect;
    allCountries: DictionaryCustomSelect;
  };
  validationErrors: AnyObject;
  setCurrentStep: (stepIndex: number) => void;
  onChangeField: (name: string, value: any) => void;
  currentStep: number;
  canSave: boolean;
  canApprove: boolean;
  isSubmitting: boolean;
  save: () => void;
  approve: () => void;
}

interface State {
  fieldsDisabled: boolean;
}

class RemittanceAccountBuilder extends PureComponent<Props, State> {
  emptyValues = [null, undefined, '', '0.00', 0];

  constructor(props: Props) {
    super(props);

    this.state = {
      fieldsDisabled: false,
    };
  }

  componentDidMount() {
    this.setStatus();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { fields } = this.props;

    if (prevProps.fields.status !== fields.status) {
      this.setStatus();
    }

    Tooltip.rebuild();
  }

  setStatus = () => {
    const { fields } = this.props;

    this.setState({
      fieldsDisabled: fields.status && fields.status !== 'draft',
    });
  };

  checkIsFilled = (field: { id: string }) => {
    const { fields } = this.props;
    return this.emptyValues.every((value) => value !== fields[field.id]);
  };

  getStatusIcon = (step: number) => {
    const { validationErrors } = this.props;
    const { fieldsDisabled } = this.state;

    if (fieldsDisabled) {
      return null;
    }

    const stepFields = fieldsConfig[step];
    const requiredFields = stepFields.filter((field) => field.isRequired);

    if (stepFields.some((field) => validationErrors[field.id])) {
      return (
        <div className='remittance-builder__tab-icon remittance-builder__tab-icon_error'>
          <Icon size={18} name='im-Error' />
        </div>
      );
    }

    if (requiredFields.every(this.checkIsFilled)) {
      return (
        <div className='remittance-builder__tab-icon remittance-builder__tab-icon_success'>
          <Icon size={18} name='im-Tick' />
        </div>
      );
    }

    if (requiredFields.some(this.checkIsFilled)) {
      return (
        <div className='remittance-builder__tab-icon remittance-builder__tab-icon_edit'>
          <Icon size={18} name='im-Edit1' />
        </div>
      );
    }

    return null;
  };

  getFieldLabel = (field: { id: string; label: string; type: string }) => {
    const { fields, getTranslate } = this.props;

    if (field.id === 'title') {
      return getTranslate(
        !!fields.status && fields.status !== 'draft'
          ? 'remittance.addRecipientAcc.field.view.title.label'
          : field.label
      );
    }
    return `${getTranslate(field.label)}${
      fields.transferCurrency && field.type === 'amount'
        ? ` (${fields.transferCurrency})`
        : ''
    }`;
  };

  getSelectValue = (id: string) => {
    const { dictionaries, fields } = this.props;

    if (!fields[id]) {
      return null;
    }

    const value = dictionaries[id]?.list?.find(
      (item) => item.value === fields[id]
    );

    if (value !== undefined) {
      return value;
    }

    if (id === `beneficiaryBankCountry` || id === `companyLegalCountry`) {
      return dictionaries[`allCountries`]?.list?.find(
        (item) => item.value === fields[id]
      );
    }

    return value;
  };

  getTitle = () => {
    const { id, fields, getTranslate } = this.props;
    const { title, remittanceRecipientAccountId } = fields;

    if (id === 'create-recipient-account') {
      return getTranslate('remittance.addRecipientAcc.header');
    }

    return `${
      title || getTranslate('remittance.editDraftRecipientAcc.header')
    } #${remittanceRecipientAccountId}`;
  };

  renderActions = () => {
    const {
      setCurrentStep,
      currentStep,
      getTranslate,
      approve,
      canApprove,
      save,
      canSave,
      isEnabled,
      isDisabledByReason,
    } = this.props;
    const { fieldsDisabled } = this.state;

    const availableSave =
      isEnabled(Messages.Remittance_CreateRecipientAccount) ||
      isDisabledByReason(
        Messages.Remittance_CreateRecipientAccount,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const availableApprove =
      isEnabled(Messages.Remittance_ApproveRecipientAccount) ||
      isDisabledByReason(
        Messages.Remittance_CreateRecipientAccount,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    return (
      <div className='remittance-builder__form-footer'>
        <Link
          url='/remittance/recipient-accounts'
          className='ui-button ui-submitted-form__button ui-button_status-outline ui-button_size-normal ui-form-fields__back-btn'>
          {getTranslate('remittance.addRecipientAcc.back.button')}
        </Link>

        <div className='remittance-builder__form-actions'>
          {availableSave && !fieldsDisabled && (
            <Button
              key='save as draft'
              disabled={!canSave}
              status='outline'
              text={getTranslate('remittance.addRecipientAcc.saveDraft.button')}
              onClick={save}
            />
          )}

          {currentStep < 3 && (
            <Button
              key='continue'
              status='primary'
              text={getTranslate('remittance.addRecipientAcc.continue.button')}
              onClick={() => setCurrentStep(currentStep + 1)}
            />
          )}

          {currentStep === 3 && availableApprove && !fieldsDisabled && (
            <Button
              tooltip={
                canApprove
                  ? ''
                  : getTranslate(
                      'remittance.addRecipientAcc.requiredFields.error'
                    )
              }
              disabled={!canApprove}
              text={getTranslate(
                'remittance.addRecipientAcc.sendApprove.button'
              )}
              status='primary'
              onClick={approve}
            />
          )}
        </div>
      </div>
    );
  };

  renderStep = (step: number) => {
    const {
      fields,
      onChangeField,
      validationErrors,
      dictionaries,
      getTranslate,
    } = this.props;
    const { fieldsDisabled } = this.state;

    return (
      <Form
        customClass='remittance-builder__form'
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        <div className='remittance-builder__form-inner'>
          <h2 className='remittance-builder__form-title'>
            {getTranslate(steps[step].title)}
          </h2>
          {fieldsConfig[step].map((field) => {
            const { id } = field;

            switch (field.type) {
              case 'text':
              case 'textarea':
              case 'amount': {
                const isAmount = field.type === 'amount';
                const InputComponent = isAmount ? InputAmount : Input;

                return (
                  <InputComponent
                    key={id}
                    id={id}
                    placeholder={field.placeholder}
                    label={this.getFieldLabel(field)}
                    value={fields[id]}
                    tooltip={field.tooltip ? getTranslate(field.tooltip) : ''}
                    type={!isAmount ? field.type : ''}
                    cleaveOptions={field.options}
                    onChange={(e) => {
                      switch (id) {
                        case 'beneficiaryAccount':
                          onChangeField(
                            id,
                            e.target.value.replace(/[^\w]/g, '')
                          );
                          break;
                        default:
                          onChangeField(id, e.target.value);
                      }
                    }}
                    disabled={fieldsDisabled}
                    customClass='remittance-builder__item'
                    error={validationErrors?.[id]}
                    modern
                  />
                );
              }
              case 'select':
                return (
                  <CustomSelect
                    key={id}
                    value={this.getSelectValue(id)}
                    options={dictionaries?.[id]?.list || []}
                    placeholder={field.placeholder}
                    label={field.label}
                    isSearchable={true}
                    isDisabled={fieldsDisabled}
                    onChange={(value) => onChangeField(id, value?.value)}
                    customClass='ui-select_large remittance-builder__item'
                    error={validationErrors?.[id]}
                    modern
                  />
                );
              case 'buttonGroup': {
                return (
                  <ButtonsGroup
                    key={id}
                    label={field.label}
                    disabled={fieldsDisabled}
                    className='ui-buttons-group_single-choice'
                    activeButtons={[fields[id]]}
                    theme='dark'
                    onClick={(values) => {
                      onChangeField(id, values[0]);
                    }}
                    error={validationErrors[id]}
                    isLoading={dictionaries[id].isLoading}>
                    {dictionaries[id].list?.map(({ label, value }) => {
                      return (
                        <Button
                          disabled={fieldsDisabled}
                          key={value}
                          status='light'
                          id={value}
                          text={label}
                        />
                      );
                    })}
                  </ButtonsGroup>
                );
              }
              default:
                return null;
            }
          })}
        </div>
        {this.renderActions()}
      </Form>
    );
  };

  render() {
    const { currentStep, getTranslate, setCurrentStep, isSubmitting } =
      this.props;

    return (
      <PageTemplate.Container>
        <div className='remittance-builder'>
          <div className='remittance-builder__content'>
            {isSubmitting ? <Loader /> : null}
            <Tabs
              infoText={this.getTitle()}
              mode='vertical'
              customClass='remittance-builder__tabs'
              defaultActiveTabIndex={currentStep}
              needUpdateDefaultTab
              onClick={setCurrentStep}>
              {steps.map(({ icon, title }, index) => {
                const tabTitle = (
                  <div className='remittance-builder__tab-title'>
                    {this.getStatusIcon(index) ?? (
                      <div className='remittance-builder__tab-icon remittance-builder__tab-icon_default'>
                        <Icon name={icon} size={18} />
                      </div>
                    )}
                    {getTranslate(title)}
                  </div>
                );

                return (
                  <Tab
                    key={`remittance-step-${title}`}
                    className='remittance-builder__tab'
                    title={tabTitle}>
                    {this.renderStep(index)}
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </div>
      </PageTemplate.Container>
    );
  }
}

export default addTranslation(addPermissions(RemittanceAccountBuilder));
