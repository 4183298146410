import './wrapperBlock.scss';

interface Props {
  title: string;
  actions?: React.ReactNode;
}

export const WrapperBlock: React.FC<Props> = ({
  title,
  children,
  actions = null,
}) => {
  return (
    <div className='wrapper-block'>
      <div className='wrapper-block__header'>
        <h4 className='wrapper-block__title'>{title}</h4>
        <div className='wrapper-block__actions'>{actions}</div>
      </div>
      <div className='wrapper-block__inner'>{children}</div>
    </div>
  );
};
