import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  IFormValues,
  IDictionary,
  FieldValidationErrors,
  LocationPayload,
} from './types';
import { useTranslation } from 'hooks';
import Utils from 'helpers/Utils';
import Form from 'components/ui/form';
import Link from 'components/ui/link';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import InputAmount from 'components/ui/inputAmount';
import { RouteLeavingGuard } from 'components/routeLeavingGuard';
import { SettingCard } from './components/SettingCard';
import SubmittedForm from 'components/formFields/components/submittedForm';
import './createFiscalReceipt.scss';

interface Props {
  isLoading: boolean;
  isFieldsDirty: boolean;
  isFormValid: boolean;
  isSuccess: boolean;
  formValues: IFormValues;
  validationErrors: FieldValidationErrors;
  payload: Omit<LocationPayload, 'refundAmount'> & {
    totalPositionAmount: number;
  };
  onChange: (value: Partial<IFormValues>) => void;
  onDelete: (settingId: string) => void;
  onOpenModal: (settingId?: string) => void;
  onSubmit: () => void;
}

const CreateFiscalReceiptView: React.FC<Props> = ({
  isLoading,
  isSuccess,
  isFieldsDirty,
  isFormValid,
  formValues,
  validationErrors,
  payload,
  onChange,
  onDelete,
  onOpenModal,
  onSubmit,
}) => {
  const getTranslate = useTranslation();
  const positionsRef = useRef<HTMLDivElement | null>(null);
  const prevPositionsLengthRef = useRef(formValues.positions.length);
  const { maxRefundAmount, currency, returnBack, totalPositionAmount } =
    payload;
  const isAmountLimitValid =
    Number(Utils.getNumberWithoutSpace(maxRefundAmount)) >=
    Number(Utils.getNumberWithoutSpace(formValues.refundAmount));

  const { taxationSystem } = useSelector((state: RootState) => {
    const { CashVoucherTaxationSystem: taxationSystem } = state.filtersValues;

    const getFormattedData = (data?: IDictionary) => {
      return {
        list:
          data?.list?.map(({ id, text }) => ({
            value: id,
            label: text,
          })) || [],
        isLoading: data?.isLoading || false,
      };
    };

    return {
      taxationSystem: getFormattedData(taxationSystem),
    };
  });

  useEffect(() => {
    if (prevPositionsLengthRef.current < formValues.positions.length) {
      positionsRef.current?.lastElementChild?.scrollIntoView({
        behavior: 'smooth',
      });
    }

    prevPositionsLengthRef.current = formValues.positions.length;
  }, [formValues.positions.length]);

  const renderForm = () => {
    return (
      <Form customClass='ui-form-fields__form' onSubmit={onSubmit}>
        <div className='ui-form-fields__container ui-form-fields__header'>
          <div className='ui-form-fields__title'>
            {getTranslate('fiscalReceipt.form.header.label')}
          </div>
        </div>

        <div className='ui-form-fields__container ui-form-fields__content'>
          <div className='ui-form-fields__inner'>
            <div className='ui-form-fields__col'>
              <div className='ui-form-fields__col-title'>
                {getTranslate('fiscalReceipt.form.GeneralInfo.label')}
              </div>
              <div className='ui-form-fields__item'>
                <InputAmount
                  id='refundAmount'
                  modern
                  isRequired
                  label={getTranslate('fiscalReceipt.detCard.Amount.label')}
                  placeholder='0.00'
                  error={
                    !isAmountLimitValid
                      ? getTranslate('payments.single.refundAmountBigger')
                      : ''
                  }
                  value={formValues.refundAmount}
                  onChange={(e) => {
                    onChange({
                      refundAmount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='ui-form-fields__item'>
                <Input
                  id='email'
                  modern
                  isRequired
                  type='email'
                  label={getTranslate('fiscalReceipt.detCard.email.label')}
                  placeholder='example@email.com'
                  value={formValues.email}
                  error={validationErrors.email}
                  onChange={(e) => {
                    onChange({
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='ui-form-fields__item'>
                <Input
                  id='iin'
                  modern
                  isRequired
                  label={getTranslate('fiscalReceipt.detCard.Itn.label')}
                  placeholder={getTranslate('fiscalReceipt.detCard.Itn.label')}
                  error={validationErrors.inn}
                  tooltip={getTranslate('fiscalReceipt.detCard.Itn.tooltip')}
                  value={formValues.inn}
                  onChange={(e) => {
                    onChange({
                      inn: e.target.value,
                    });
                  }}
                  maxLength={12}
                  cleaveOptions={{
                    numericOnly: true,
                  }}
                />
              </div>
              <div className='ui-form-fields__item'>
                <Input
                  id='group'
                  modern
                  isRequired
                  label={getTranslate('fiscalReceipt.detCard.VenDet.label')}
                  placeholder={getTranslate(
                    'fiscalReceipt.detCard.VenDet.label'
                  )}
                  value={formValues.group}
                  onChange={(e) => {
                    onChange({
                      group: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='ui-form-fields__item'>
                <CustomSelect
                  id='taxationSystem'
                  modern
                  isRequired
                  placeholder={getTranslate(
                    'fiscalReceipt.detCard.TaxSys.label'
                  )}
                  label={getTranslate('fiscalReceipt.detCard.TaxSys.label')}
                  isLoading={taxationSystem.isLoading}
                  options={taxationSystem.list}
                  value={taxationSystem.list.find(
                    ({ value }) => value === formValues.taxationSystem
                  )}
                  onChange={({ value }) => {
                    onChange({
                      taxationSystem: value,
                    });
                  }}
                />
              </div>
              <div className='ui-form-fields__item'>
                <Input
                  id='paymentAddress'
                  modern
                  tooltip={getTranslate(
                    'fiscalReceipt.detCard.Address.tooltip'
                  )}
                  label={getTranslate('fiscalReceipt.detCard.Address.label')}
                  placeholder={getTranslate(
                    'fiscalReceipt.detCard.Address.label'
                  )}
                  value={formValues.paymentAddress}
                  onChange={(e) => {
                    onChange({
                      paymentAddress: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className='ui-form-fields__col'>
              <div className='ui-form-fields__col-title'>
                {getTranslate('fiscalReceipt.form.PositionSettings.label')}
              </div>

              {formValues.positions.length ? (
                <div className='receipt-form__positions' ref={positionsRef}>
                  {formValues.positions.map(
                    ({ id, quantity, price, positionDescription }) => (
                      <div key={id} className='ui-form-fields__item'>
                        <SettingCard
                          data={{
                            id,
                            title: positionDescription,
                            content: [
                              {
                                key: getTranslate(
                                  'fiscalReceipt.detCard.Price.label'
                                ),
                                value: price,
                              },
                              {
                                key: getTranslate(
                                  'fiscalReceipt.detCard.Quantity.label'
                                ),
                                value: quantity,
                              },
                            ],
                          }}
                          onEdit={onOpenModal}
                          onDelete={onDelete}
                        />
                      </div>
                    )
                  )}
                </div>
              ) : null}

              <div className='ui-form-fields__item'>
                <Button
                  iconPosition='left'
                  iconSize={15}
                  icon='im-Plus'
                  status='outline'
                  text={getTranslate('fiscalReceipt.AddPosition.Button')}
                  onClick={() => onOpenModal()}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='receipt-form__info'>
          <div className='receipt-form__info-item'>
            <p className='receipt-form__info-title'>
              {getTranslate('payments.single.refundAvailable')}
            </p>
            <div>
              <span className='receipt-form__info-amount'>
                {maxRefundAmount}
              </span>
              &nbsp;
              <span className='receipt-form__info-currency'>{currency}</span>
            </div>
          </div>
          <div className='receipt-form__info-item'>
            <p className='receipt-form__info-title'>
              {getTranslate('fiscalReceipt.totals.position.amount')}
            </p>
            <div>
              <span className='receipt-form__info-amount'>
                {Utils.getNumberWithSpace(totalPositionAmount.toFixed(2))}
              </span>
              &nbsp;
              <span className='receipt-form__info-currency'>{currency}</span>
            </div>
          </div>
        </div>

        <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
          <Link
            url={returnBack}
            className='ui-button ui-button_size-normal ui-form-fields__back-btn '>
            {getTranslate('common.cancel.button')}
          </Link>
          <Button
            disabled={!isFormValid}
            text={getTranslate('fiscalReceipt.detCard.Submit.button')}
            status='primary'
            type='submit'
          />
        </div>
      </Form>
    );
  };

  const renderSuccessForm = () => {
    return (
      <SubmittedForm
        id='payout'
        status='success'
        title={getTranslate('fiscalReceipt.modal.success.title')}
        text={getTranslate('fiscalReceipt.modal.success.text')}
        secondaryButtonText={getTranslate('common.back.button')}
        onSecondaryButtonLink={returnBack}
      />
    );
  };

  return (
    <div className='receipt-form'>
      <div className='ui-form-fields'>
        {isLoading ? <Loader /> : null}

        {!isSuccess ? renderForm() : renderSuccessForm()}
      </div>

      <RouteLeavingGuard blockWhen={isFieldsDirty} />
    </div>
  );
};

export { CreateFiscalReceiptView };
