import classnames from 'classnames';
import Icon from 'components/ui/icon';
import './overallChip.scss';

interface Props {
  value: number;
}

export const OverallChip: React.FC<Props> = ({ value }) => {
  return (
    <div
      className={classnames('overall-chip', {
        'overall-chip_increase': value > 0,
        'overall-chip_decrease': value < 0,
      })}>
      <Icon
        name={value > 0 ? 'arrowUp' : 'arrowDown'}
        className='overall-chip__icon'
        size={9}
      />
      <span className='overall-chip__value'>{Math.abs(value)}%</span>
    </div>
  );
};
