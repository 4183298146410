import { useTranslation } from 'hooks';
import './smallBanner.scss';

export const SmallBanner: React.FC = () => {
  const getTranslate = useTranslation();

  return (
    <div className='card small-banner'>
      <div className='small-banner__inner'>
        <div>
          <h4 className='small-banner__title'>
            {getTranslate('getStarted.smallBanner.title')}
          </h4>{' '}
          <p className='small-banner__text'>
            {getTranslate('getStarted.smallBanner.text')}
          </p>
        </div>
        <a
          className='small-banner__button'
          href='https://campaigns.ecommpay.com/ecommpay-is-rebranding'>
          <div className='small-banner__button-text'>
            {getTranslate('getStarted.smallBanner.button')}
          </div>
        </a>
      </div>
    </div>
  );
};
