import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { saveQuickActions } from 'api/home';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import ModalTitle from 'components/modal/components/ModalTitle';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import { QuickActionCard } from 'pages/home/components';
import { fillEmptyActions } from 'pages/home/modules/QuickActions/helpers';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { v4 as uuid } from 'uuid';
import { findContainer } from './helpers';
import { SortableContainer, SortableItem } from './components';
import './homeBlocksSettings.scss';

interface Props {
  callback: (data?: any) => void;
}

export const QuickActionsSetting: React.FC<Props> = ({ callback }) => {
  const getTranslate = useTranslation();
  // default falues in case quickActions still is loading
  const { enabled = [], available = [] } = useSelector(
    (state: RootState) => state.home.quickActions
  );
  const [isLoading, setLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState<string | null>(null);
  const [items, setItems] = useState({
    enabled: fillEmptyActions(enabled).map((item) => {
      if (item) {
        return item;
      }

      return {
        id: uuid(),
        title: '',
        icon: '',
      };
    }),
    available: available.filter(
      ({ id }) => !enabled.some((item) => id === item.id)
    ),
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        tolerance: 5,
        delay: 100,
      },
    })
  );

  const getCurrentActiveItem = useMemo(() => {
    if (!currentActiveId) {
      return;
    }

    const activeContainer = findContainer(currentActiveId, items);

    if (activeContainer) {
      return items[activeContainer].find((item) => item.id === currentActiveId);
    }
  }, [currentActiveId, items]);

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setCurrentActiveId(String(active.id));
  }, []);

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over || active.id === over.id) {
        return;
      }

      const overContainer = findContainer(over.id, items);
      const activeContainer = findContainer(active.id, items);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const activeIndex = activeItems.findIndex(
            ({ id }) => id === active.id
          );
          const overIndex = overItems.findIndex(({ id }) => id === over.id);
          const activeItem = activeItems[activeIndex];
          const overItem = overItems[overIndex];
          const newActiveItems = [...activeItems];
          const newOverItems = [...overItems];

          if (overContainer === 'enabled') {
            const isOverItemFilled = overItem.title && overItem.icon;

            if (isOverItemFilled) {
              if (
                items.enabled.filter((item) => item?.title && item?.icon)
                  .length >= 4
              ) {
                newOverItems[overIndex] = activeItem;
                newActiveItems[activeIndex] = overItem;
              }

              return {
                ...items,
                [activeContainer]: newActiveItems,
                [overContainer]: newOverItems,
              };
            } else {
              newOverItems[overIndex] = activeItem;
            }

            return {
              ...items,
              [activeContainer]: activeItems.filter(
                ({ id }) => id !== active.id
              ),
              [overContainer]: newOverItems,
            };
          }

          const newIndex = overIndex >= 0 ? overIndex : overItems.length + 1;
          const emptySpot = {
            id: uuid(),
            title: '',
            icon: '',
          };

          return {
            ...items,
            [activeContainer]: activeItems.map((item, idx) =>
              idx === activeIndex ? emptySpot : item
            ),
            [overContainer]: [
              ...overItems.slice(0, newIndex),
              activeItem,
              ...overItems.slice(newIndex, overItems.length),
            ],
          };
        });
      }
    },
    [items]
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over || active.id === over.id) {
        return;
      }

      const overContainer = findContainer(over.id, items);
      const activeContainer = findContainer(active.id, items);

      if (!activeContainer || !overContainer) {
        return;
      }

      if (activeContainer === overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const activeIndex = activeItems.findIndex(
            ({ id }) => id === active.id
          );
          const overIndex = overItems.findIndex(({ id }) => id === over.id);

          return {
            ...items,
            [overContainer]: arrayMove(overItems, activeIndex, overIndex),
          };
        });
      }
    },
    [items]
  );

  const handleSave = async () => {
    setLoading(true);

    const payload = items.enabled
      .map((item, idx) => ({
        ...item,
        index: idx,
      }))
      .filter((item) => item.title);

    try {
      const { quickActions } = await saveQuickActions(payload);
      setLoading(false);
      callback(quickActions);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}

      <ModalTitle>{getTranslate('getStarted.quickActions.header')}</ModalTitle>
      <ModalContent>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}>
          <div className='home-blocks-setting'>
            <div className='home-blocks-setting__block'>
              <h5 className='home-blocks-setting__block-title'>
                {getTranslate('getStarted.quickActions.connected.label')}
              </h5>
              <SortableContainer
                id='enabled'
                items={items.enabled}
                className='home-blocks-setting__sortable-quick-actions'>
                {items.enabled.map(({ id, title, icon }) => {
                  if (title && icon) {
                    return (
                      <SortableItem key={id} id={id}>
                        <QuickActionCard
                          title={getTranslate(title)}
                          iconName={icon}
                          isDraggable
                        />
                      </SortableItem>
                    );
                  }

                  return (
                    <SortableItem key={id} id={id} disabled>
                      <div className='card drop-area'></div>
                    </SortableItem>
                  );
                })}
              </SortableContainer>
            </div>

            <hr className='utils-separator' />

            <div className='home-blocks-setting__info'>
              <h5 className='home-blocks-setting__info-title'>
                {getTranslate('getStarted.quickActions.warning.title')}
              </h5>
              <p className='home-blocks-setting__info-text'>
                {getTranslate('getStarted.quickActions.warning.text')}
              </p>
            </div>

            <div className='home-blocks-setting__block'>
              <h5 className='home-blocks-setting__block-title'>
                {getTranslate('getStarted.quickActions.notConnected.label')}
              </h5>
              <SortableContainer
                id='available'
                items={items.available}
                className='home-blocks-setting__sortable-quick-actions'>
                {items.available.length ? (
                  items.available.map(({ id, title, icon }) => (
                    <SortableItem key={id} id={id}>
                      <QuickActionCard
                        title={getTranslate(title)}
                        iconName={icon}
                        isDraggable
                      />
                    </SortableItem>
                  ))
                ) : (
                  <div className='card drop-area'></div>
                )}
              </SortableContainer>
            </div>
          </div>

          {createPortal(
            <DragOverlay modifiers={[snapCenterToCursor]}>
              {currentActiveId ? (
                <QuickActionCard
                  title={getTranslate(getCurrentActiveItem?.title ?? '')}
                  iconName={getCurrentActiveItem?.icon}
                  isDraggable
                  isOverlay
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </ModalContent>
      <ModalFooter>
        <Button
          text={getTranslate('common.cancel.button')}
          status='outline'
          size='normal'
          onClick={() => callback()}
        />
        <Button
          text={getTranslate('common.save.button')}
          status='primary'
          size='normal'
          onClick={handleSave}
        />
      </ModalFooter>
    </>
  );
};
