import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { saveTodayBlocks } from 'api/home';
import ModalContent from 'components/modal/components/ModalContent';
import ModalFooter from 'components/modal/components/ModalFooter';
import ModalTitle from 'components/modal/components/ModalTitle';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import { SummaryCard } from 'pages/home/components';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { findContainer, getRowCount } from './helpers';
import { SortableContainer, SortableItem } from './components';
import './homeBlocksSettings.scss';

interface Props {
  callback: (data?: any) => void;
}

const ITEM_HEIGHT = 138;
const GAP = 16;
const rowHeight = ITEM_HEIGHT + GAP;

export const TotalSummariesSetting: React.FC<Props> = ({ callback }) => {
  const getTranslate = useTranslation();
  const callbackRef = useRef<() => void | undefined>();
  const [isLoading, setLoading] = useState(false);
  const { enabled, available } = useSelector(
    (state: RootState) => state.home.todayBlocks
  );
  const [currentActiveId, setCurrentActiveId] = useState<string | null>(null);
  const [items, setItems] = useState({
    enabled,
    available: available.filter(
      ({ id }) => !enabled.some((item) => id === item.id)
    ),
  });
  const [containerHeight, setContainerHeight] = useState({
    enabled: ITEM_HEIGHT,
    available: ITEM_HEIGHT,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        tolerance: 5,
        delay: 100,
      },
    })
  );

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current();
      callbackRef.current = undefined;
    }
  }, [items]);

  useEffect(() => {
    calculateInitialHeight();
  }, []);

  const calculateInitialHeight = () => {
    setContainerHeight((containerHeight) => {
      const enabledRows = getRowCount(items.enabled.length) - 1;
      const availableRows = getRowCount(items.available.length) - 1;

      return {
        enabled: containerHeight.enabled + enabledRows * rowHeight,
        available: containerHeight.available + availableRows * rowHeight,
      };
    });
  };

  const handleDragStart = useCallback(({ active }: DragStartEvent) => {
    setCurrentActiveId(String(active.id));
  }, []);

  const handleDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      if (!over || active.id === over.id) {
        return;
      }

      const overContainer = findContainer(over.id, items);
      const activeContainer = findContainer(active.id, items);

      if (!overContainer || !activeContainer) {
        return;
      }

      if (activeContainer !== overContainer) {
        callbackRef.current = () => {
          setContainerHeight((containerHeight) => {
            const overColumns = items[overContainer].length;
            const activeColumns = items[activeContainer].length;
            const prevOverRow = getRowCount(overColumns);
            const currentOverRow = getRowCount(overColumns + 1);
            const prevActiveRow = getRowCount(activeColumns);
            const currentActiveRow = getRowCount(activeColumns - 1);

            return {
              ...containerHeight,
              [activeContainer]:
                prevActiveRow === currentActiveRow
                  ? containerHeight[activeContainer]
                  : containerHeight[activeContainer] - rowHeight,
              [overContainer]:
                prevOverRow === currentOverRow
                  ? containerHeight[overContainer]
                  : containerHeight[overContainer] + rowHeight,
            };
          });
        };

        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const activeIndex = activeItems.findIndex(
            ({ id }) => id === active.id
          );
          const overIndex = overItems.findIndex(({ id }) => id === over.id);
          const newIndex = overIndex >= 0 ? overIndex : overItems.length + 1;

          return {
            ...items,
            [activeContainer]: activeItems.filter(({ id }) => id !== active.id),
            [overContainer]: [
              ...overItems.slice(0, newIndex),
              activeItems[activeIndex],
              ...overItems.slice(newIndex, overItems.length),
            ],
          };
        });
      }
    },
    [items]
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over || active.id === over.id) {
        return;
      }

      const overContainer = findContainer(over.id, items);
      const activeContainer = findContainer(active.id, items);

      if (!activeContainer || !overContainer) {
        return;
      }

      if (activeContainer === overContainer) {
        setItems((items) => {
          const activeItems = items[activeContainer];
          const overItems = items[overContainer];
          const activeIndex = activeItems.findIndex(
            ({ id }) => id === active.id
          );
          const overIndex = overItems.findIndex(({ id }) => id === over.id);

          return {
            ...items,
            [overContainer]: arrayMove(overItems, activeIndex, overIndex),
          };
        });
      }
    },
    [items]
  );

  const handleSave = async () => {
    setLoading(true);

    try {
      const { getStartedInfoBlocks } = await saveTodayBlocks(items.enabled);
      setLoading(false);
      callback(getStartedInfoBlocks);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}

      <ModalTitle>{getTranslate('getStarted.totalSummary.header')}</ModalTitle>
      <ModalContent>
        <DndContext
          sensors={sensors}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}>
          <div className='home-blocks-setting'>
            <div className='home-blocks-setting__block'>
              {/* <h5 className='home-blocks-setting__block-title'>
                {getTranslate('getStarted.totalSummary.connected.label')}
              </h5> */}
              <SortableContainer
                id='enabled'
                items={items.enabled}
                className='home-blocks-setting__sortable-total-summaries'
                // style={{
                //   height: containerHeight.enabled,
                // }}
              >
                {items.enabled.map(({ id }) => (
                  <SortableItem
                    key={id}
                    id={id}
                    disabled={items.enabled.length === 1}>
                    <SummaryCard
                      name={id}
                      isDraggable
                      isRedirectDisabled
                      isDragDisabled={items.enabled.length === 1}
                    />
                  </SortableItem>
                ))}
              </SortableContainer>
            </div>

            {/* <div className='home-blocks-setting__block'>
              <h5 className='home-blocks-setting__block-title'>
                {getTranslate('getStarted.totalSummary.notConnected.label')}
              </h5>
              <SortableContainer
                id='available'
                items={items.available}
                className='home-blocks-setting__sortable-total-summaries'
                style={{
                  height: containerHeight.available,
                }}>
                {items.available.length ? (
                  items.available.map(({ id }) => (
                    <SortableItem key={id} id={id}>
                      <SummaryCard name={id} isDraggable isRedirectDisabled />
                    </SortableItem>
                  ))
                ) : (
                  <div className='card drop-area'></div>
                )}
              </SortableContainer>
            </div> */}
          </div>

          {createPortal(
            <DragOverlay modifiers={[snapCenterToCursor]}>
              {currentActiveId ? (
                <SummaryCard name={currentActiveId} isDraggable isOverlay />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </ModalContent>
      <ModalFooter>
        <Button
          text={getTranslate('common.cancel.button')}
          status='outline'
          size='normal'
          onClick={() => callback()}
        />
        <Button
          text={getTranslate('common.save.button')}
          status='primary'
          size='normal'
          onClick={handleSave}
        />
      </ModalFooter>
    </>
  );
};
