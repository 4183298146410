import cards from 'images/getStarted/cards.svg';
import high from 'images/getStarted/high-risk.png';
import uploadDocs from 'images/getStarted/uploadDocs.svg';
import wallet from 'images/getStarted/wallet.svg';
import commOfferReady from 'images/getStarted/commOfferReady.svg';
import docs from 'images/getStarted/docs.svg';
import swytch from 'images/getStarted/swytch.png';
import waiting from 'images/getStarted/waiting.svg';

export const pageImages = {
  cards,
  high,
  uploadDocs,
  wallet,
  commOfferReady,
  docs,
  swytch,
  waiting,
};
