import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getAnalysisDataByCodeName } from 'api/home';
import { setGraphBlockData } from 'actions/home';
import { LineChart, VerticalBarChart } from './components';
import { LineChartData, VerticalBarChartData } from 'reducers/home';
import './graphCard.scss';

interface Props {
  name: string;
  type: 'line_chart' | 'vertical_bar_chart';
}

export const GraphCard: React.FC<Props> = ({ name, type }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [isError, setError] = useState(false);
  const { state, periodType, currentCurrency, refreshId } = useSelector(
    (state: RootState) => ({
      state: state.home.graphBlockData[name] ?? {},
      periodType: state.home.periodType,
      currentCurrency: state.home.currentCurrency,
      refreshId: state.home.refreshId,
    })
  );
  const { data, isLoaded = false } = state;
  const [isLoading, setLoading] = useState(!isLoaded);

  useEffect(() => {
    if (!isLoaded) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      fetchData();
    } else {
      isFirstRender.current = false;
    }
  }, [refreshId, currentCurrency, periodType]);

  const fetchData = async () => {
    setLoading(true);
    setError(false);

    try {
      const result = await getAnalysisDataByCodeName({
        blockCode: name,
        currency: currentCurrency,
        periodType,
      });

      dispatch(
        setGraphBlockData({
          name: name,
          data: result,
        })
      );
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderCharts = () => {
    switch (type) {
      case 'line_chart':
        return (
          <LineChart
            data={data as LineChartData}
            isLoading={isLoading}
            isError={isError}
            onFetch={fetchData}
          />
        );
      case 'vertical_bar_chart':
        return (
          <VerticalBarChart
            data={data as VerticalBarChartData}
            isLoading={isLoading}
            isError={isError}
            onFetch={fetchData}
          />
        );

      default:
        return null;
    }
  };

  return <div className='card graph-card'>{renderCharts()}</div>;
};
