import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

export const SortableContainer: React.FC<{
  id: string;
  items: { id: UniqueIdentifier }[];
  className?: string;
  style?: React.CSSProperties;
}> = ({ id, items, className, style, children }) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext items={items}>
      <div ref={setNodeRef} className={className} style={style}>
        {children}
      </div>
    </SortableContext>
  );
};
