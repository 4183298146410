import { useTranslation } from 'hooks';
import { VerticalBarChartView } from './VerticalBarChartView';
import { VerticalBarChartData } from 'reducers/home';
import Link from 'components/ui/link/Link';
import { Skeleton } from 'components/ui/skeleton';
import Button from 'components/ui/button';

interface Props {
  data?: VerticalBarChartData;
  isLoading: boolean;
  isError: boolean;
  onFetch: () => void;
}

export const VerticalBarChart: React.FC<Props> = ({
  data,
  isLoading,
  isError,
  onFetch,
}) => {
  const getTranslate = useTranslation();

  if (!data || isLoading || isError) {
    return (
      <>
        <div className='graph-card__title-wrapper'>
          <Skeleton height={14} width='40%' />
          {isError ? (
            <Button
              id='refresh'
              status='light'
              icon='im-Refill'
              iconSize={14}
              iconPosition='left'
              text='Refresh'
              onClick={onFetch}
            />
          ) : null}
        </div>

        <div>
          {[1, 2, 3].map((item) => (
            <div key={item} className='vertical-bar-chart__item'>
              <div className='vertical-bar-chart__country-wrapper'>
                <Skeleton
                  height={20}
                  width={20}
                  style={{
                    borderRadius: '100%',
                  }}
                />
                <Skeleton height={14} width={150} />
              </div>
              <div className='vertical-bar-chart__value-wrapper'>
                <Skeleton height={14} width={56} />
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      <div className='graph-card__title-wrapper'>
        <h5 className='graph-card__title'>{getTranslate(data.title)}</h5>
        <Link className='overview-graphs__link' url='/analytics'>
          {getTranslate('getStarted.overview.createChart')}
        </Link>
      </div>
      <VerticalBarChartView data={data} />
    </>
  );
};
