import ACTIONS from 'constants/actionTypes';
import ActionReducer from 'types/ActionReducer';

export type TodayBlockData = {
  title: string;
  totalValue: string;
  text: string;
  percentageChange?: number;
  tooltip: string;
  redirectPath: string;
};

export type VerticalBarChartData = {
  title: string;
  type: 'vertical_bar_chart';
  chartDate: {
    country_code: string;
    country_name: string;
    increase: number;
    value: number;
  }[];
};

export type LineChartData = {
  title: string;
  type: 'line_chart';
  chartData: {
    points: {
      actual: number[];
      previous: number[];
      increase: number;
    };
    series: {
      actual: string[];
      previous: string[];
    };
  };
  currency: string;
  period: {
    actual: {
      from: string;
      to: string;
    };
    previous: {
      from: string;
      to: string;
    };
  };
};

export type TodayBlock = {
  id: string;
  index?: number;
};

export type QuickAction = {
  icon: string;
  id: string;
  title: string;
  index?: number;
};

type TodayBlockState = {
  [key: string]: {
    isLoaded?: boolean;
    data?: Partial<TodayBlockData>;
  };
};

type GraphBlockState = {
  [key: string]: {
    isLoaded?: boolean;
    data?: VerticalBarChartData | LineChartData;
  };
};

type TodayBlocksState = {
  isLoaded?: boolean;
  enabled: TodayBlock[];
  available: TodayBlock[];
};

type QuickActionsState = {
  isLoaded?: boolean;
  enabled: QuickAction[];
  available: QuickAction[];
};

const initialState: {
  currentCurrency: string;
  periodType: string;
  todayBlockData: TodayBlockState;
  graphBlockData: GraphBlockState;
  todayBlocks: TodayBlocksState;
  quickActions: QuickActionsState;
  refreshId: string;
} = {
  currentCurrency: 'USD',
  periodType: 'currentMonth',
  todayBlockData: {},
  graphBlockData: {},
  todayBlocks: {
    enabled: [],
    available: [],
  },
  quickActions: {
    enabled: [],
    available: [],
  },
  refreshId: '',
};

export const home = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_HOME_TODAY_BLOCKS:
      return {
        ...state,
        todayBlocks: {
          isLoaded: true,
          ...(payload.todayBlocks as TodayBlocksState),
        },
      };

    case ACTIONS.SET_HOME_TODAY_BLOCK_DATA_BY_NAME:
      return {
        ...state,
        todayBlockData: {
          ...state.todayBlockData,
          [payload.name]: {
            isLoaded: true,
            data: payload.data,
          },
        },
      };

    case ACTIONS.RESET_HOME_TODAY_BLOCK_DATA_BY_NAME:
      return {
        ...state,
        todayBlockData: {},
      };

    case ACTIONS.SET_HOME_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: payload.currency as string,
      };

    case ACTIONS.SET_HOME_QUICK_ACTIONS:
      return {
        ...state,
        quickActions: {
          isLoaded: true,
          ...(payload.quickActions as QuickActionsState),
        },
      };

    case ACTIONS.SET_HOME_GRAPH_BLOCK_DATA_BY_NAME:
      return {
        ...state,
        graphBlockData: {
          ...state.graphBlockData,
          [payload.name]: {
            isLoaded: true,
            data: payload.data,
          },
        },
      };

    case ACTIONS.SET_HOME_GRAPH_PERIOD:
      return {
        ...state,
        periodType: payload.periodType as string,
      };

    case ACTIONS.SET_HOME_REFRESH_ID:
      return {
        ...state,
        refreshId: payload.id as string,
      };

    case ACTIONS.RESET_ALL: {
      return initialState;
    }

    default:
      return state;
  }
};
