import { BannerStatus } from 'pages/finance/modules/balanceRequest/components';
import './TemporaryBannerDWHService.scss';

export const TemporaryBannerDWHService = () => {
  return (
    <BannerStatus
      status='Correction'
      title='temporaryBannerDWHService.title'
      text='temporaryBannerDWHService.text'
    />
  );
};
