import React, { Component, Fragment, MouseEvent, ReactNode } from 'react';
import Tooltip from 'react-tooltip';
import { isEqual, throttle } from 'lodash-es';
import classNames from 'classnames';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import Link from 'components/ui/link';
import RoundButton from 'components/ui/roundButton';
import Panel from 'components/ui/panel';
import CustomScrollbar from 'components/ui/customScrollbar';
import savedEntities from 'constants/savedEntities';
import urlsMap from 'constants/urlsMap';
import SavedEntityItem from 'types/savedEntity';
import setNoChangeWordOrder from 'helpers/setNoChangeWordOrder';
import { getTooltip } from 'helpers/getTooltip';

import menuConfiguration from 'constants/menuConfiguration';
import { getSectionBySavedEntity } from 'helpers/getSectionByTableName';
import './savedEntities.scss';

interface Props extends IntlProps {
  currentId?: string;
  entityKey: keyof typeof savedEntities;
  items: SavedEntityItem[];
  buildUrl: (item: SavedEntityItem) => string;
  onRemove: ({ id, isCurrent }: { id: any; isCurrent: boolean }) => void;
  closeAllEntities: () => void;
  customClass?: string;
  isNew?: boolean;
  isFirstTab?: boolean;
  currentUrl?: string;
}

interface State {
  visibleList: any[];
  hiddenList: any[];
  needRebuildLists: boolean;
  isShowHiddenList: boolean;
}

interface GetLink {
  text: string;
  url: string;
  canRemove: boolean;
  isCurrent: boolean;
  id?: string;
  icon?: string;
}

class SavedEntities extends Component<Props, State> {
  private rootComponentRef;
  private visibleListRef;

  constructor(props) {
    super(props);
    this.state = {
      visibleList: this.getList(props.items),
      hiddenList: [],
      needRebuildLists: false,
      isShowHiddenList: false,
    };

    this.buildLists = throttle(this.buildLists, 100);

    this.attachEvents();
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  componentDidMount(): void {
    this.buildLists();
  }

  componentDidUpdate(prevProps, prevState): void {
    const { needRebuildLists } = this.state;
    if (!isEqual(prevProps, this.props)) {
      this.setState({
        needRebuildLists: true,
      });
    }
    if (needRebuildLists) {
      this.buildLists();
    }

    if (prevState.isShowHiddenList !== this.state.isShowHiddenList) {
      setTimeout(() => this.checkTooltips(), 50);
    }
  }

  render() {
    const { customClass, closeAllEntities, isFirstTab } = this.props;
    const { visibleList, hiddenList, isShowHiddenList } = this.state;

    return (
      <div
        ref={(el) => {
          this.rootComponentRef = el;
        }}
        className={classNames('saved-entities', customClass, {
          'saved-entities_no-first': !isFirstTab,
        })}
        id='saved-entities'>
        <Fragment>
          <ul
            ref={(el) => {
              this.visibleListRef = el;
            }}
            className='saved-entities__list'>
            {visibleList}
          </ul>
          <div className='saved-entities__buttons'>
            {hiddenList.length > 0 && (
              <>
                <RoundButton
                  id='dots-button'
                  status='primary'
                  customClass='saved-entities__list-dots-more-button'
                  icon='im-Dots'
                  iconSize={12}
                  size={24}
                  onClick={this.toggleMenu}
                />
                <Panel
                  isOpened={isShowHiddenList}
                  animationDuration={50}
                  animationNameShow='fadeId'
                  renderCloseControl={false}
                  customClass='ui-panel_saved-entities'
                  excludeFromCloseTrigger={['.saved-entities__list-dots']}
                  onClose={(e) => {
                    // workaround fix DE-2301
                    if (e.target.parentElement.id === 'dots-button') {
                      return;
                    }
                    this.toggleMenu();
                  }}>
                  <div className='saved-entities__list-popup'>
                    <CustomScrollbar customHeight={184}>
                      {hiddenList}
                    </CustomScrollbar>
                  </div>
                </Panel>
              </>
            )}
            {visibleList.length + hiddenList.length > (isFirstTab ? 2 : 1) && (
              <RoundButton
                icon='im-Close'
                iconSize={8}
                size={24}
                onClick={closeAllEntities}
              />
            )}
          </div>
        </Fragment>
      </div>
    );
  }

  attachEvents = () => {
    window.addEventListener('resize', this.buildLists);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.buildLists);
  };

  buildLists = () => {
    const { items, isFirstTab = true } = this.props;
    const { rootComponentRef, visibleListRef } = this;

    if (!visibleListRef) return false;

    this.setState(
      {
        needRebuildLists: false,
        visibleList: this.getList(items),
      },
      () => {
        const itemsDOM = visibleListRef?.querySelectorAll(
          '.saved-entities__item'
        );
        const rootWidth: number = rootComponentRef.offsetWidth;
        const buttonsWidth: number = 72;
        const paddings = 40;
        const visibleList: SavedEntityItem[] = [];
        const hiddenList: SavedEntityItem[] = [];
        let sumWidthVisibleList: number = 0;

        itemsDOM.forEach((item, index) => {
          sumWidthVisibleList += item.offsetWidth;

          // Если первым элементом будет List,
          // его не нужно пушить в списки, т.к он будет добавлен в getList,
          // но в расчетах доступной ширины он учавствовать должен
          if (isFirstTab && index === 0) return;

          const currentIndex = isFirstTab ? index : index + 1;

          if (rootWidth > sumWidthVisibleList + buttonsWidth + paddings) {
            visibleList.push(items[currentIndex - 1]);
          } else {
            hiddenList.push(items[currentIndex - 1]);
          }
        });

        this.setState(
          {
            visibleList: this.getList(visibleList),
            hiddenList: this.getList(hiddenList, false),
          },
          this.checkTooltips
        );
      }
    );
  };

  getList(items, isVisibleList: boolean = true): ReactNode[] {
    const { getTranslate, buildUrl, currentId, entityKey, isNew, isFirstTab } =
      this.props;
    const list: ReactNode[] = [];

    if (isVisibleList && isFirstTab) {
      const icon = menuConfiguration.find(
        (item) => item.key === getSectionBySavedEntity(entityKey)
      );

      list.push(
        this.getLink({
          text: getTranslate(`savedEntities.${entityKey}`),
          url: `/${urlsMap[entityKey] || entityKey}`,
          canRemove: false,
          isCurrent: !currentId && !isNew,
          icon: icon?.icon,
        })
      );
    }

    const {
      location: { pathname, search },
    } = window;
    items.forEach((item) => {
      const url = buildUrl(item);
      let text = item.caption || item.id;
      if (item.hiddenParams && item.hiddenParams.localeKey) {
        text = getTranslate(item.hiddenParams.localeKey);
        if (item.hiddenParams.id) {
          text += ` ${item.hiddenParams.id}`;
        }
      } else if (item.urlParams && item.urlParams.name) {
        text = item.urlParams.name;
      }

      list.push(
        this.getLink({
          text: getTranslate(text),
          url,
          id: item.id,
          canRemove: true,
          isCurrent: currentId === item.id || pathname + search === url,
        })
      );
    });

    return list;
  }

  getLink({
    id = '',
    url,
    text,
    canRemove,
    isCurrent,
    icon,
  }: GetLink): ReactNode {
    return (
      <li
        key={id}
        className={classNames('saved-entities__item', {
          'saved-entities__item_active': isCurrent,
          'saved-entities__item_fixed': !canRemove,
        })}>
        <span
          className='saved-entities__item-outer'
          data-place='bottom'
          data-delay-show={800}>
          {isCurrent ? (
            <span className='saved-entities__item-inner utils-overflow-dots'>
              {icon && (
                <Icon name={icon} size={16} className='saved-entities__icon' />
              )}
              {setNoChangeWordOrder(text)}
            </span>
          ) : (
            <Link
              className='saved-entities__item-inner utils-overflow-dots'
              key={id}
              url={url}>
              {icon && (
                <Icon name={icon} size={16} className='saved-entities__icon' />
              )}
              {setNoChangeWordOrder(text)}
            </Link>
          )}
        </span>
        {canRemove && (
          <span className='saved-entities__item-remove'>
            <RoundButton
              size={20}
              iconSize={8}
              icon='im-Close'
              onClick={(event) => this.onRemoveTab(event, id, isCurrent)}
            />
          </span>
        )}
      </li>
    );
  }

  onRemoveTab = (
    event: MouseEvent<HTMLElement>,
    id: string,
    isCurrent: boolean
  ): void => {
    event.stopPropagation();
    const { onRemove } = this.props;
    onRemove({ id, isCurrent });
  };

  toggleMenu = (): void => {
    this.setState({
      isShowHiddenList: !this.state.isShowHiddenList,
    });
  };

  checkTooltips = () => {
    const savedEntity = '.saved-entities__item-outer';
    document.body.querySelectorAll(savedEntity).forEach((item) => {
      item.setAttribute(
        'data-tip',
        getTooltip(item, '.saved-entities__item-inner') || ''
      );
    });
    Tooltip.rebuild();
  };
}

export default addTranslation(SavedEntities);
