import './demoPageImage.scss';
import demoSettingUp from 'images/getStarted/demoSettingUp.svg';

export const DemoPageImage = () => {
  return (
    <div
      className='demo-page-image'
      style={{
        maskImage: `url(${demoSettingUp})`,
        WebkitMaskImage: `url(${demoSettingUp})`,
      }}></div>
  );
};
