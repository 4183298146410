import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { Redirect } from 'react-router-dom';

import api from 'api/getStarted';
import { WithRouterProps } from 'decorators/withRouter';
import I18nHelpers from 'i18n/I18nHelpers';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import CustomScrollbar from 'components/ui/customScrollbar';

import getPath from 'helpers/path';

import './demostageinvite.scss';
import { DemoPageImage } from 'components/DemoPageImage';

type Props = IntlProps & WithRouterProps;

const REQUEST_INTERVAL = 20 * 1000;

class DemoStageInvite extends React.Component<Props, any> {
  code;
  checkReadinessInterval;

  constructor(props) {
    super(props);
    this.code = this.urlParams.get('code');
    this.checkReadinessInterval = setInterval(
      this.checkReadiness,
      REQUEST_INTERVAL
    );
  }

  componentDidMount() {
    this.checkReadiness();
  }

  componentWillUnmount() {
    clearInterval(this.checkReadinessInterval);
  }

  render() {
    const { getTranslate } = this.props;

    if (!this.code) {
      return <Redirect to={getPath('/login')} />;
    }

    return (
      <CustomScrollbar>
        <PageTemplate.Container>
          <div className='demo-stage-invite__wrapper'>
            <div className='demo-stage-invite'>
              <div className='demo-stage-invite__description'>
                {getTranslate('auth.login.demostagecreating.text3')}
              </div>
              <div className='demo-stage-invite__header-image'>
                <DemoPageImage />
              </div>
              <div className='demo-stage-invite__description'>
                {getTranslate('auth.login.demostagecreating.text1')}
              </div>
              <div className='demo-stage-invite__description_extra'>
                {I18nHelpers.getJsx(
                  getTranslate('auth.login.demostagecreating.text2')
                )}
              </div>
            </div>
          </div>
        </PageTemplate.Container>
      </CustomScrollbar>
    );
  }

  get urlParams() {
    const { history } = this.props;
    if (!history.location.search) return new URLSearchParams();
    return new URLSearchParams(history.location.search);
  }

  checkReadiness = async () => {
    try {
      await api.confirmInvite(this.code);
    } catch (e) {
      this.props.history.push('/login');
    }
  };
}

export default addTranslation(DemoStageInvite);
