import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { forwardRef } from 'react';

export const ChartView = forwardRef<
  HighchartsReact.RefObject,
  HighchartsReact.Props
>((props, ref) => {
  return <HighchartsReact ref={ref} highcharts={Highcharts} {...props} />;
});
