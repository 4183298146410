import { useHistory } from 'react-router-dom';
import { useTranslation } from 'hooks';
import Button from 'components/ui/button';
import bannerImage from './images/form-continue.svg';
import './mainBanner.scss';

export const MainBanner: React.FC = () => {
  const getTranslate = useTranslation();
  const history = useHistory();

  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__inner'>
          <div>
            <h2 className='main-banner__title'>
              {getTranslate('getStarted.DAFIsReady.label')}
            </h2>
            <p className='main-banner__text'>
              {getTranslate('getStarted.DAFIsReady.text')}
            </p>
          </div>
          <Button
            status='light'
            text={getTranslate('getStarted.agreeActivate.button')}
            onClick={() => history.push('/maf')}
          />
        </div>
        <img
          className='main-banner__image'
          src={bannerImage}
          alt='banner image'
        />
      </div>
    </div>
  );
};
