import React, { Component } from 'react';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import { Tab, TabsContainer as Tabs } from 'components/ui/tabs';

import FormFields from './FormFields';
import SubmittedForm from './components/submittedForm';

import FormFieldsProps from './FormFieldsProps';
import './formFields.scss';

interface Props extends FormFieldsProps, IntlProps {
  title: string;
  loadMore?: (name: string) => void;
}

class FormView extends Component<Props> {
  render() {
    const { id, getTranslate } = this.props;

    return (
      <div
        className={classNames('ui-form-fields', `ui-form-fields_${id}`, {
          [`${this.props.customClass}`]: !!this.props.customClass,
        })}>
        {this.props.isSubmitted ? (
          this.renderResult()
        ) : (
          <>
            <div className='ui-form-fields__container ui-form-fields__container_header'>
              <div className='ui-form-fields__title'>
                {getTranslate(this.props.title)}
              </div>
            </div>
            {this.props.tabs ? this.renderTabs() : this.renderFields()}
          </>
        )}
      </div>
    );
  }

  renderTabs = () => {
    const {
      tabs,
      getTranslate,
      formFields,
      filtersValues,
      fieldsConfig,
      filtersValuesCustom,
      onChangeTab,
      onBeforeChange,
    } = this.props;

    if (!tabs) {
      return null;
    }

    return (
      <Tabs
        theme='brand'
        needUpdateDefaultTab
        defaultActiveTabIndex={tabs.findIndex((tab) => tab.isCurrent)}
        customClass='ui-form-fields__tabs ui-tabs_form-fields'
        onClick={onChangeTab}
        onBeforeChange={onBeforeChange}>
        {tabs.map((tab) => {
          return (
            <Tab key={tab.id} id={tab.id} title={getTranslate(tab.label)}>
              {formFields ? (
                React.cloneElement(formFields, {
                  filtersValues,
                  fieldsConfig,
                })
              ) : (
                <FormFields
                  {...this.props}
                  dictionaries={{
                    ...filtersValues,
                    ...filtersValuesCustom,
                  }}
                />
              )}
            </Tab>
          );
        })}
      </Tabs>
    );
  };

  renderFields = () => {
    return this.props.formFields ? (
      React.cloneElement(this.props.formFields, {
        filtersValues: this.props.filtersValues,
        fieldsConfig: this.props.fieldsConfig,
      })
    ) : (
      <FormFields
        {...this.props}
        dictionaries={{
          ...this.props.filtersValues,
          ...this.props.filtersValuesCustom,
        }}
      />
    );
  };

  renderResult = () => {
    return (
      <SubmittedForm
        id={this.props.id}
        title={this.props.submittedTitle}
        text={this.props.submittedText}
        primaryButtonText={this.props.repeatCreateButtonText}
        onPrimaryButtonClick={this.props.onReset}
        status='success'
        secondaryButtonText={this.props.backText}
        onSecondaryButtonLink={this.props.backUrl}
        link={this.props.link}
      />
    );
  };
}

export default addTranslation(FormView);
