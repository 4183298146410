import classnames from 'classnames';
import Icon from 'components/ui/icon';
import { Skeleton } from 'components/ui/skeleton';
import './quickActionCard.scss';

interface Props {
  title?: string;
  iconName?: string;
  isNew?: boolean;
  isLoading?: boolean;
  isDraggable?: boolean;
  isOverlay?: boolean;
  onClick?: () => void;
}

export const QuickActionCard: React.FC<Props> = ({
  title = '',
  iconName = '',
  isNew = false,
  isLoading = false,
  isDraggable = false,
  isOverlay = false,
  onClick,
}) => {
  if (isLoading) {
    return (
      <div className='card quick-action-card'>
        <Skeleton height={40} width={40} style={{ borderRadius: '100%' }} />
        <Skeleton height={14} />
      </div>
    );
  }

  return (
    <div
      className={classnames('card quick-action-card', {
        'quick-action-card_new': isNew,
        'quick-action-card_overlay': isOverlay,
        'quick-action-card_draggable': isDraggable,
      })}
      onClick={onClick}>
      {isDraggable ? (
        <Icon
          name='draggable'
          size={16}
          className='quick-action-card__draggable'
        />
      ) : null}
      <div
        className={classnames('quick-action-card__icon', {
          'quick-action-card__icon_new': isNew,
        })}>
        <Icon name={iconName} size={20} />
      </div>
      <h5
        className={classnames('quick-action-card__title', {
          'quick-action-card__title_new': isNew,
        })}>
        {title}
      </h5>
    </div>
  );
};
