import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import DropZone from 'components/ui/dropzone';
import FileTemplate from 'components/ui/fileTemplate';
import Env from 'helpers/Env';
import './wbMulti.scss';

interface Props extends IntlProps {
  isCreating: boolean;
  file: File | null;
  errorMessage: string;
  onUpload: (file: File) => void;
  onDelete: () => void;
  onCreate: () => void;
  onCancel: () => void;
}

class WbMulti extends PureComponent<Props> {
  render() {
    const {
      file,
      isCreating,
      errorMessage,
      onUpload,
      onDelete,
      onCreate,
      onCancel,
      getTranslate,
    } = this.props;

    return (
      <div className='wb-multi-form'>
        <div className='wb-multi-form__container'>
          <Form customClass='wb-multi-form__form' onSubmit={onCreate}>
            <div className='wb-multi-form__example'>
              <FileTemplate
                title={getTranslate('risks.addToList.massAdd.template.header')}
                url={Env.getApiUrl() + `files/rcs.csv`}
              />
            </div>
            <div className='wb-multi-form__dropzone'>
              <DropZone
                noClick={true}
                file={file}
                acceptFiles='.csv'
                onDrop={(files) => {
                  onUpload(files[0]);
                }}
                onDeleteFile={onDelete}
                errorMessage={errorMessage}
              />
            </div>
            <div className='wb-multi-form__buttons'>
              <Button
                text={getTranslate('common.cancel.button')}
                onClick={onCancel}
                status='outline'
              />
              <Button
                text={getTranslate('common.apply.button')}
                status='success'
                type='submit'
                disabled={!file}
                customClass='wb-multi-form__apply'
              />
            </div>
            {isCreating && <Loader />}
          </Form>
        </div>
      </div>
    );
  }
}

export default addTranslation(WbMulti);
