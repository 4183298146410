import { useTranslation } from 'hooks';
import { LineChartData } from 'reducers/home';
import { LineChartView } from './LineChartView';
import { Skeleton } from 'components/ui/skeleton';
import Button from 'components/ui/button';

interface Props {
  data?: LineChartData;
  isLoading: boolean;
  isError: boolean;
  onFetch: () => void;
}

export const LineChart: React.FC<Props> = ({
  data,
  isLoading,
  isError,
  onFetch,
}) => {
  const getTranslate = useTranslation();

  if (!data || isLoading || isError) {
    return (
      <>
        <div className='graph-card__title-wrapper'>
          <Skeleton height={14} width='40%' />
          {isError ? (
            <Button
              id='refresh'
              status='light'
              icon='im-Refill'
              iconSize={14}
              iconPosition='left'
              text='Refresh'
              onClick={onFetch}
            />
          ) : null}
        </div>
        <div className='graph-card__info'>
          <div style={{ maxWidth: 162, width: '100%' }}>
            <div className='graph-card__info-wrapper'>
              <Skeleton height={27} width={56} />
              <Skeleton height={27} width={32} />
            </div>
            <Skeleton height={17} width='100%' />
          </div>
          <div style={{ maxWidth: 162, width: '100%' }}>
            <div className='graph-card__info-wrapper'>
              <Skeleton height={27} width={56} />
              <Skeleton height={27} width={32} />
            </div>
            <Skeleton height={17} width='100%' />
          </div>
        </div>
        <Skeleton height={140} />
      </>
    );
  }

  return (
    <>
      <h5 className='graph-card__title'>{getTranslate(data.title)}</h5>
      <LineChartView data={data} />
    </>
  );
};
