import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem: React.FC<{
  id: UniqueIdentifier;
  disabled?: boolean;
}> = ({ id, disabled, children }) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    isDragging,
    transition,
  } = useSortable({ id, disabled });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {!isDragging ? children : <div className='card drag-overlay'></div>}
    </div>
  );
};
