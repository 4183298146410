import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { TodayBlockData, TodayBlock, QuickAction } from 'reducers/home';
import { StoreProps } from 'store';

export const setTodayBlockData = (payload: {
  name: string;
  data: TodayBlockData;
}) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_TODAY_BLOCK_DATA_BY_NAME,
        payload,
      })
    );
  };
};

export const setCurrentCurrency = (payload: { currency: string }) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_CURRENT_CURRENCY,
        payload,
      })
    );
  };
};

export const setQuickActions = (payload: {
  quickActions: {
    enabled?: QuickAction[];
    available?: QuickAction[];
  };
}) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_QUICK_ACTIONS,
        payload,
      })
    );
  };
};

export const setTodayBlocks = (payload: {
  todayBlocks: {
    enabled?: TodayBlock[];
    available?: TodayBlock[];
  };
}) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_TODAY_BLOCKS,
        payload,
      })
    );
  };
};

export const resetAllTodayBlockData = () => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.RESET_HOME_TODAY_BLOCK_DATA_BY_NAME,
      })
    );
  };
};

export const setGraphBlockData = (payload: { name: string; data: any }) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_GRAPH_BLOCK_DATA_BY_NAME,
        payload,
      })
    );
  };
};

export const setGraphPeriod = (payload: { periodType: string }) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_GRAPH_PERIOD,
        payload,
      })
    );
  };
};

export const setRefreshId = (payload: { id: string }) => {
  return (dispatch: StoreProps['dispatch']) => {
    dispatch(
      createAction({
        type: ACTIONS.SET_HOME_REFRESH_ID,
        payload,
      })
    );
  };
};
