import LocalStorage from 'helpers/LocalStorage';
import BaseMessageHandler from '../BaseMessageHandler';
import { wl } from 'actions/settings';

const defaultTheme = {
  colors: {
    brand: {
      main: '#4B007C',
      highlight: '#300050',
      dark: '#3E0066',
    },
    accent: {
      main: '#00cc24',
      highlight: '#00a61d',
      dark: '#008016',
    },
  },
  font: {
    default: {
      body: "'ProximaNova', sans-serif",
      heading: "'ProximaNova', sans-serif",
    },
  },
  componentStyle: {
    'drop-zone': {
      'border-radius': '16',
    },
    'file-template': {
      'border-radius': '12',
    },
    card: {
      'border-radius': '24',
    },
    button: {
      'border-radius': '8',
    },
    'buttons-group': {
      'border-radius': '8',
    },
    'input-default': {
      'border-radius': ' 8',
    },
    'input-modern': {
      'border-radius': '12',
    },
  },
};

export default class GET_WL extends BaseMessageHandler {
  default = () => {
    const { dispatch, message } = this;
    let theme = defaultTheme;

    if (message.payload?.themeConfig) {
      theme = message.payload?.themeConfig;
    }

    // setting dynamic app colors
    Object.keys(theme.colors).forEach((name) => {
      Object.entries(theme.colors[name]).forEach(([type, color]) => {
        const defaultValue = defaultTheme.colors[name][type];
        this.setCssVariables(name, type, color || defaultValue);
      });
    });

    // setting dynamic app fonts
    const lang = LocalStorage.get('noAuthLang');
    const fontStyle = theme.font[lang] ?? theme.font.default;

    Object.entries(fontStyle).forEach(([type, value]) => {
      this.setCssVariables('font', type, String(value));
    });

    // setting dynamic border radius
    Object.entries(theme.componentStyle).forEach(([name]) => {
      Object.entries(theme.componentStyle[name]).forEach(([type, value]) => {
        this.setCssVariables(name, type, `${value}px`);
      });
    });

    dispatch(wl(message.payload));
  };

  setCssVariables = (name: string, type: string, value: string) => {
    const root = document.documentElement;
    root.style.setProperty(`--${name}-${type}`, value);
  };
}
