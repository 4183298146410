import React, { PureComponent } from 'react';
import { finalizeMoto } from 'api/payouts';
import Loader from 'components/ui/loader';
import CustomScrollbar from 'components/ui/customScrollbar';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import './paymentFrame.scss';

interface Props {
  callback: (isSuccess) => void;
  content: { src: string; motoPaymentId };
}

interface State {
  isLoading: boolean;
  width: string;
  height: string;
}

const statuses = {
  'epframe.payment.success': 'success',
  'epframe.payment.decline': 'decline',
  'epframe.payment.fail': 'decline',
  'epframe.payment.error': 'error',
};

const PADDING = 55;
class PaymentFrame extends PureComponent<Props, State> {
  private iframe;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      width: '',
      height: '',
    };
  }
  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  render() {
    const {
      content: { src },
    } = this.props;
    const { width, height, isLoading } = this.state;
    return (
      <div
        className='modal-payment-frame'
        style={{ width: `${width}px`, height: `${height + PADDING}px` }}>
        {isLoading && <Loader />}
        <CustomScrollbar>
          <div className='modal-payment-frame__inner'>
            <iframe
              title='payment page'
              src={src}
              ref={(el) => {
                this.iframe = el;
              }}
              onLoad={this.handleIframeLoad}
              style={{ width: `${width}px`, height: `${height}px` }}
            />
          </div>
        </CustomScrollbar>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  handleIframeLoad = () => {
    this.setState((state) => ({
      isLoading: false,
      width: state.width == '' ? '400' : state.width,
      height: state.height == '' ? '800' : state.height,
    }));
  };

  messageHandler = async (e) => {
    const data = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
    if (
      data.message === 'epframe.loaded' ||
      data.message === 'epframe.resize'
    ) {
      this.setState((state) => ({
        isLoading: false,
        width: data.data?.width,
        height: data.data?.height,
      }));
    }

    if (statuses[data.message]) {
      await this.finalize(data.message);
    }
  };

  finalize = async (message) => {
    const { callback } = this.props;
    this.setState({ isLoading: true });
    const {
      content: { motoPaymentId },
    } = this.props;

    if (isNotAvailableForSupport(Messages.MotoPayment_Finalize)) return;

    const response = await finalizeMoto({
      motoPaymentId,
      status: statuses[message],
    });
    callback(response);
  };
}

export default PaymentFrame;
