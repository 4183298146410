import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'store';
import { usePermission } from 'hooks';
import landingState from 'constants/landingState';
import getPath from 'helpers/path';
import Messages from 'constants/rpcTypes';

const RootContainer: React.FC = () => {
  const { isEnabled } = usePermission();
  const user = useSelector((state: RootState) => state.user);

  switch (user.landingState) {
    case landingState.maf:
      return <Redirect to={getPath('/maf')} />;
    case landingState.demoStage:
      if (isEnabled(Messages.DemoStage_Project)) {
        return <Redirect to={getPath('/get-started')} />;
      } else if (isEnabled(Messages.Payments_List)) {
        return <Redirect to={getPath('/payments')} />;
      }
      return <Redirect to={getPath('/profile')} />;
    case landingState.default:
      if (isEnabled(Messages.GetStarted_View)) {
        return <Redirect to={getPath('/home')} />;
      } else if (isEnabled(Messages.Payments_List)) {
        return <Redirect to={getPath('/payments')} />;
      }
      return <Redirect to={getPath('/profile')} />;
    default:
      return <Redirect to={getPath('/profile')} />;
  }
};

export default RootContainer;
