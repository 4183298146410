import React from 'react';
import classNames from 'classnames';
import I18nHelpers from 'i18n/I18nHelpers';
import { useTranslation } from 'hooks';
import { TransferStatus } from '../../modules/transfer/form/types';
import { SettlementStatus } from '../../modules/settlement/form/types';
import './bannerStatus.scss';

interface Props {
  title?: string;
  text?: string;
  status: TransferStatus | SettlementStatus;
  additionalInfo?: React.ReactNode;
  actions?: React.ReactNode;
}

export const statusMapper = {
  Draft: 'draft',
  'Request sent': 'request-sent',
  Cancelled: 'cancelled',
  Correction: 'correction',
  'In progress': 'in-progress',
  'In progress cancellable': 'in-progress',
  Success: 'success',
  Rejected: 'rejected',
  'Bank refusal': 'bank-refusal',
  'Bank processing': 'bank-processing',
} as const;

const BannerStatus: React.FC<Props> = ({
  status,
  title,
  text,
  additionalInfo,
  actions,
}) => {
  const getTranslate = useTranslation();

  return (
    <div
      className={classNames(
        'banner-status',
        `banner-status_${statusMapper[status]}`
      )}>
      <div className='banner-status__wrapper'>
        <div className='banner-status__info'>
          {title ? (
            <h3 className='banner-status__title'>{getTranslate(title)}</h3>
          ) : null}
          {text ? (
            <p className='banner-status__text'>
              {I18nHelpers.getJsx(getTranslate(text))}
            </p>
          ) : null}
          {additionalInfo ? (
            <div className='banner-status__additional'>{additionalInfo}</div>
          ) : null}
        </div>
        {actions ? (
          <div className='banner-status__actions'>{actions}</div>
        ) : null}
      </div>
    </div>
  );
};

export { BannerStatus };
