import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from 'store';
import landingState from 'constants/landingState';
import getPath from 'helpers/path';
import GetStartedPage from './onboarding/';

const GetStarted: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);

  if (user.landingState !== landingState.demoStage) {
    return <Redirect to={getPath('/')} />;
  }

  return <GetStartedPage />;
};

export default GetStarted;
