import React, { Component } from 'react';
import { connect } from 'react-redux';

import apiDictionaries from 'api/dictionaries';
import apiFilters from 'api/filters';
import apiPermissions from 'api/permissions';
import apiUser from 'api/user';
import { initFilters } from 'actions/filters';
import {
  initAllConfiguration,
  updateConfiguration,
} from 'actions/configuration';
import { closeModal } from 'actions/modal';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import SocketService from 'helpers/SocketService';
import LocalStorage from 'helpers/LocalStorage';
import { isEmpty } from 'components/ui/table/helpers';
import UserActivityWatcher from 'components/userActivityWatcher';
import Loader from 'components/ui/loader';
import { AnyObject } from 'types/Common';
import ThemeConfig from 'types/ThemeConfig';
import App from './App';
import { StoreProps } from 'store';
import Messages from 'constants/rpcTypes';
import { WebSocketContext } from 'hooks/useWebSocketConnection';

interface ConnectedProps {
  user: AnyObject;
  app: AnyObject;
  permissions: boolean;
  configuration: AnyObject;
  filtersValues: AnyObject;
  filters: AnyObject;
  settings: {
    themeConfig: ThemeConfig;
    wl: string;
    wlId: number;
  };
  isConfirmLogout: boolean;
}

type Props = ConnectedProps & StoreProps & WithPermissions;

type State = { isWSConnected: boolean };

class AppContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setUserId();
    this.attachChangeUserIdListener();
    this.state = {
      isWSConnected: false,
    };
  }

  async componentDidMount() {
    const result = await Promise.all([
      this.initFiltersList(),
      this.getTableColumns(),
      this.getPermissions(),
      this.getConfig(),
      SocketService.init(),
    ]);

    const ws = result[4];

    this.setState({
      isWSConnected: ws.isOpened,
    });
  }

  componentWillUnmount(): void {
    const { dispatch } = this.props;
    dispatch(closeModal());
    SocketService.destroy();
  }

  render() {
    const { user, settings, app, configuration, isConfirmLogout } = this.props;
    const { isWSConnected } = this.state;
    if (!this.appIsReady()) return <Loader />;

    return (
      <WebSocketContext.Provider value={isWSConnected}>
        <UserActivityWatcher instanceId={app.instanceId} />
        <App
          user={user}
          settings={settings}
          isMenuExpanded={configuration.isMenuExpanded}
          isConfirmLogout={isConfirmLogout}
          updateConfig={this.updateConfig}
        />
      </WebSocketContext.Provider>
    );
  }

  async getTableColumns() {
    const { dispatch } = this.props;

    try {
      const list = await apiDictionaries.getTableColumns();
      dispatch(initAllConfiguration(list));
    } catch (e) {}
  }

  async initFiltersList() {
    const { dispatch } = this.props;

    try {
      const filters = await apiFilters.getFiltersList();
      dispatch(initFilters(filters));
    } catch (e) {}
  }

  async getPermissions() {
    try {
      await apiPermissions.getPermissions();
    } catch (e) {}
  }

  async getConfig() {
    try {
      const { isMenuExpanded } = await apiUser.getConfig({
        param: 'isMenuExpanded',
      });
      this.props.dispatch(updateConfiguration({ isMenuExpanded }));
    } catch (e) {
      console.error('Error occurred while getting menu config', e);
    }
  }

  setUserId = () => {
    const { user } = this.props;
    if (user) {
      LocalStorage.set('userId', user.id);
    }
  };

  attachChangeUserIdListener = () => {
    LocalStorage.addEventListener((event) => {
      const { key, oldValue, newValue } = event;
      if (key === 'userId' && oldValue && newValue && oldValue !== newValue) {
        window.location.reload();
      }
    });
  };

  appIsReady = (): boolean => {
    const { app, filters, configuration, permissions } = this.props;
    return !!app && !!filters && !!configuration && permissions;
  };

  updateConfig = (isOpen) => {
    if (!this.props.isEnabled(Messages.UserConfig_Set)) return;
    try {
      this.props.dispatch(updateConfiguration({ isMenuExpanded: isOpen }));
      apiUser.setConfig({ param: 'isMenuExpanded', value: isOpen });
    } catch (e) {
      console.error('Error occurred while updating menu config', e);
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  app: state.app,
  permissions: !isEmpty(state.permissions),
  configuration: state.configuration,
  filtersValues: state.filtersValues,
  filters: state.filters,
  settings: state.settings,
  isConfirmLogout: state.app.isConfirm,
});

export default connect(mapStateToProps)(addPermissions(AppContainer));
