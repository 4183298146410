import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { throttle } from 'lodash-es';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import Link from 'components/ui/link';
import PrivacyLinks from 'pages/app/components/policyLinks';
import ChangeLang from 'components/changeLang';
import CustomScrollbar from 'components/ui/customScrollbar';
import { AnyObject } from 'types/Common';
import './pageAuth.scss';
import './loginForm.scss';

interface OwnProps {
  title?: string;
  text?: string;
  subtext?: string;
  children: any;
}

interface ConnectedProps {
  logoUrl: string;
  logoLightUrl: string;
  loginPictureUrl: string;
  loginPictureUrlMobile: string;
  wlId: number;
  user: AnyObject;
  app: AnyObject;
}

type Props = OwnProps & ConnectedProps & IntlProps & WithRouterProps;

interface State {
  background: string;
}

class PageAuthWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setBackground = throttle(this.setBackground, 500);
    this.state = {
      background: '',
    };
  }
  componentDidMount() {
    this.setBackground();
    window.addEventListener('resize', this.setBackground);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setBackground);
  }

  render() {
    const {
      title,
      text,
      subtext,
      logoUrl,
      logoLightUrl,
      wlId,
      children,
      getTranslate,
    } = this.props;
    return (
      <div className='page-auth'>
        <CustomScrollbar>
          <div className='page-auth__inner'>
            <div className='page-auth__col page-auth__col-form'>
              <div className='page-auth__col-inner'>
                <div className='page-auth__header'>
                  {logoUrl ? (
                    <Link url='/login' className='page-auth__logo'>
                      <img
                        src={logoUrl}
                        alt='logo'
                        className='page-auth__logo-pic'
                      />
                    </Link>
                  ) : (
                    <span />
                  )}
                </div>
                <div className='page-auth__form'>
                  <div className='page-auth__form-header'>
                    <h1 className='page-auth__title'>{getTranslate(title)}</h1>
                    {wlId !== 7 && (
                      <ChangeLang customClass='page-auth__lang menu-context_no-hover' />
                    )}
                  </div>
                  {text && (
                    <div className='page-auth__text'>{getTranslate(text)}</div>
                  )}
                  {subtext && (
                    <div className='page-auth__text'>
                      {getTranslate(subtext)}
                    </div>
                  )}
                  {children}
                </div>
                <div className='page-auth__footer'>
                  <PrivacyLinks />
                </div>
              </div>
            </div>
            <div
              className={classNames('page-auth__col page-auth__col-banner', {
                'page-auth__col-banner_effect': wlId !== 1,
              })}
              style={{
                backgroundImage: this.state.background,
              }}>
              <div className='page-auth__header-mobile'>
                {logoLightUrl && (
                  <Link url='/login' className='page-auth__logo'>
                    <img
                      src={logoLightUrl}
                      alt='logo'
                      className='page-auth__logo-pic'
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </CustomScrollbar>
      </div>
    );
  }

  setBackground = () => {
    const { loginPictureUrl, loginPictureUrlMobile } = this.props;
    if (document.body.clientWidth > 768) {
      this.setState({
        background: loginPictureUrl ? `url(${loginPictureUrl})` : '',
      });
      return;
    }
    this.setState({
      background: loginPictureUrlMobile ? `url(${loginPictureUrlMobile})` : '',
    });
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  logoUrl: state.settings?.themeConfig?.logoUrl,
  logoLightUrl: state.settings?.themeConfig?.logoLightUrl,
  loginPictureUrl: state.settings?.themeConfig?.loginPictureUrl,
  loginPictureUrlMobile: state.settings?.themeConfig?.loginPictureUrlMobile,
  wlId: state.settings?.wlId,
  user: state.user,
  app: state.app,
});

export default withRouter(
  connect(mapStateToProps)(addTranslation(PageAuthWrapper))
);
