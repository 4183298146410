import { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { RootState } from 'store';
import { useTranslation } from 'hooks';
import { openModal } from 'actions/modal';
import { getTodayBlocks } from 'api/home';
import { resetAllTodayBlockData, setTodayBlocks } from 'actions/home';
import { SummaryCard, WrapperBlock } from 'pages/home/components';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
import 'swiper/swiper-bundle.css';
import './totalSummaries.scss';

SwiperCore.use([Navigation]);

export const TotalSummaries: React.FC = memo(() => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const { todayBlocks, interfaceLang } = useSelector((state: RootState) => ({
    todayBlocks: state.home.todayBlocks,
    interfaceLang: state.user.interfaceLang,
  }));
  const { enabled, isLoaded = false } = todayBlocks;
  const prevInterfaceLang = useRef(interfaceLang);

  // reset and refetch data in summary card on lang change
  useEffect(() => {
    if (prevInterfaceLang.current !== interfaceLang) {
      dispatch(resetAllTodayBlockData());
    }

    prevInterfaceLang.current = interfaceLang;
  }, [interfaceLang]);

  useEffect(() => {
    if (!isLoaded) {
      fetchTotalSummaries();
    }
  }, [isLoaded]);

  const fetchTotalSummaries = async () => {
    try {
      const { getStartedInfoBlocks } = await getTodayBlocks();
      dispatch(setTodayBlocks({ todayBlocks: getStartedInfoBlocks }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpenModal = () => {
    dispatch(
      openModal({
        modalId: 'TotalSummariesSetting',
        callback: (result) => {
          if (result) {
            dispatch(setTodayBlocks({ todayBlocks: result }));
          }
        },
      })
    );
  };

  return (
    <WrapperBlock
      title={getTranslate('getStarted.totalSummary.header')}
      actions={
        <Button
          status='light'
          text={getTranslate('common.edit.button')}
          icon='im-Configurator'
          iconPosition='left'
          iconSize={14}
          customClass='total-summaries-edit'
          onClick={handleOpenModal}
        />
      }>
      <div className='total-summaries'>
        <Swiper
          navigation={
            isLoaded
              ? {
                  nextEl: '.swiper-arrow-right',
                  prevEl: '.swiper-arrow-left',
                  disabledClass: 'swiper-arrow-disabled',
                }
              : false
          }
          breakpoints={{
            320: {
              slidesPerView: 2.1,
              spaceBetween: 8,
            },
            560: {
              slidesPerView: 3.5,
              spaceBetween: 8,
            },
            769: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
          }}>
          {isLoaded
            ? enabled.map(({ id }) => (
                <SwiperSlide key={id}>
                  <SummaryCard name={id} />
                </SwiperSlide>
              ))
            : [1, 2, 3, 4].map((item) => (
                <SwiperSlide key={item}>
                  <SummaryCard name='loading' isPreLoading />
                </SwiperSlide>
              ))}
        </Swiper>
        <button className='swiper-arrow-left'>
          <Icon name='im-Arrow-left' />
        </button>
        <button className='swiper-arrow-right'>
          <Icon name='im-Arrow-right' />
        </button>
      </div>
    </WrapperBlock>
  );
});
