import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'hooks';
import { RootState } from 'store';
import { setGraphPeriod } from 'actions/home';
import { GraphCard, WrapperBlock } from 'pages/home/components';
import ButtonsGroup from 'components/ui/buttonsGroup';
import Button from 'components/ui/button';
import CustomSelect from 'components/ui/customSelect';
import './overviewGraphs.scss';

export const OverviewGraphs: React.FC = memo(() => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const periodType = useSelector((state: RootState) => state.home.periodType);

  const handlePeriodChange = (periodType: string) => {
    dispatch(setGraphPeriod({ periodType }));
  };

  const filterOptions = [
    {
      value: 'currentMonth',
      label: getTranslate('getStarted.overview.currentMonth'),
    },
    {
      value: 'last7Days',
      label: getTranslate('getStarted.overview.last7days'),
    },
    {
      value: 'last30Days',
      label: getTranslate('getStarted.overview.last30days'),
    },
  ];

  return (
    <WrapperBlock
      title={getTranslate('getStarted.overview.header')}
      actions={
        window.innerWidth > 768 ? (
          <ButtonsGroup
            separate
            activeButtons={[periodType]}
            mode='singleRequired'
            onClick={(value) => handlePeriodChange(value[0])}>
            {filterOptions.map(({ label, value }) => (
              <Button key={value} id={value} text={label} />
            ))}
          </ButtonsGroup>
        ) : (
          <CustomSelect
            modern
            theme='light'
            placeholder=' '
            value={filterOptions.find((item) => item.value === periodType)}
            options={filterOptions}
            customClass='overview-graphs-filter'
            onChange={(value) => handlePeriodChange(value.value)}
          />
        )
      }>
      <div className='overview-graphs'>
        <GraphCard name='gross_volume_period' type='line_chart' />
        <GraphCard
          name='customers_by_country_period'
          type='vertical_bar_chart'
        />
      </div>
    </WrapperBlock>
  );
});
